import {Map, View} from 'ol';
import {register} from 'ol/proj/proj4';
import proj4 from 'proj4'
import {Projection, toLonLat} from "ol/proj";
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import {WMTS, XYZ} from "ol/source";
import {defaults, ScaleLine} from "ol/control";
import {Tile} from "ol/layer";
import Geohash from 'latlon-geohash'
import GeolocationButton from "ol-ext/control/GeolocationButton";

window.dataLayer = window.dataLayer || [];
proj4.defs("EPSG:3067", "+proj=utm +zone=35 +ellps=GRS80 +units=m +no_defs");
proj4.defs("EPSG:25835", "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

register(proj4);

const finProjection = new Projection({
    code: 'EPSG:3067',
    extent: [-548576.000000, 6291456.000000, 1548576.000000, 8388608.000000]
});

const norProjection = new Projection({
    code: 'EPSG:25835',
    extent: [-3500000, 3500000, 2045984, 9045984]
});
//
// const customTileLoader = (tile, src) => {
//
//     const client = new XMLHttpRequest();
//     client.open('GET', src);
//     // Uncomment to pass authentication header
//     client.withCredentials = true;
//     client.setRequestHeader("Authorization", "Basic " + window.btoa("b8b34ada-8045-482d-9b3d-aac7d78191fb:"));
//     client.onload = function () {
//         tile.getImage().src = src;
//     };
//     client.send();
// }

const peruskarttaSource = new WMTS({
    attributions: 'Taustakartta &copy; NLS.fi 2021',
    layer: 'maastokartta',
    matrixSet: 'ETRS-TM35FIN',
    format: 'image/png',
    projection: finProjection,
    url: 'https://avoin-karttakuva.maanmittauslaitos.fi/avoin/wmts?api-key=b8b34ada-8045-482d-9b3d-aac7d78191fb',
    tileGrid: new WMTSTileGrid({
        extent: [-548576.000000, 6291456.000000, 1548576.000000, 8388608.000000],
        resolutions: [8192, 4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5],
        matrixIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
    }),
    style: 'default'
});

var norgeskartSource = new WMTS({
    attributions: '&copy; kartverket.no 2021',
    layer: 'topo4',
    matrixSet: 'EPSG:25835',
    format: 'image/png',
    projection: norProjection,
    url: 'https://opencache.statkart.no/gatekeeper/gk/gk.open_wmts',
    tileGrid: new WMTSTileGrid({
        extent: [-3500000, 3500000, 2045984, 9045984],
        resolutions: [21664, 10832, 5416, 2708, 1354, 677, 338.5, 169.25, 84.625, 42.3125, 21.15625, 10.578125, 5.2890625, 2.64453125, 1.322265625, 0.6611328125, 0.33056640625, 0.165283203125],
        matrixIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(i => 'EPSG:25835:' + i)
    }),
    style: 'default'
});

var slopeSource = new XYZ({
    attributions: 'Kaltevuus &copy; NLS.fi 2020 / HyllyCorp',
    layer: 'lappi_slope:korkeusmalli',
    matrixSet: 'ETRS-TM35FIN',
    format: 'image/gif',
    projection: finProjection,
    url: 'https://slope.hylly.org/tiles/slope/{z}/{x}/{-y}.gif',
    tileGrid: new WMTSTileGrid({
        extent: [-548576.000000, 6291456.000000, 1548576.000000, 8388608.000000],
        resolutions: [8192, 4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5],
        matrixIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(i => 'ETRS-TM35FIN:' + i)
    }),
    style: ''
});

const map = new Map({
    controls: defaults().extend([
        new ScaleLine(),
        new GeolocationButton()
    ]),
    layers: [
        new Tile({
            opacity: 1.0,
            source: peruskarttaSource
        }),
        new Tile({
            opacity: 1.0,
            source: norgeskartSource
        }),
        new Tile({
            opacity: 0.7,
            source: slopeSource,
            minZoom: 8
        })
    ],
    target: 'map',
    view: new View({
        projection: finProjection,
        center: [445171, 7196225],
        zoom: 4,
        minZoom: 4,
        maxZoom: 12,
        enableRotation: false
    })
});

map.on('moveend', function (e) {
    const view = map.getView();
    const xy = view.getCenter();
    const zoom = view.getZoom();
    if (zoom > 8) {
        const lonlat = toLonLat(xy, finProjection)
        const geohash = Geohash.encode(lonlat[1], lonlat[0], 4);
        window.dataLayer.push({event: 'moveend', geohash})
    }
})
